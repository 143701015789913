<script>
import vuetableFormatter from '@src/mixins/vuetableFormatters'

const i18nCommon = 'COMMON'
const i18nKey = 'IMPORT_JOBS'

export default {
    mixins: [vuetableFormatter],
    data() {
        return {
            importJobFields: [
                {
                    name: 'created_at',
                    title: this.getI18n(i18nCommon, 'created_at'),
                    sortField: 'created_at',
                },
                {
                    name: 'type',
                    title: this.getI18n(i18nKey, 'FIELDS.type'),
                    sortField: 'type',
                    formatter: this.formatImportTypes,
                },
                {
                    name: 'lines',
                    title: this.getI18n(i18nKey, 'FIELDS.lines'),
                    sortField: 'lines',
                    formatter: (value) => {
                        return this.padLeft(value)
                    },
                },
                {
                    name: 'successes',
                    title: this.getI18n(i18nKey, 'FIELDS.successes'),
                    sortField: 'successes',
                    formatter: (value) => {
                        return this.padLeft(value)
                    },
                },
                {
                    name: 'errors',
                    title: this.getI18n(i18nKey, 'FIELDS.errors'),
                    sortField: 'errors',
                    formatter: (value) => {
                        return this.padLeft(value)
                    },
                },
                {
                    name: 'failures',
                    title: this.getI18n(i18nKey, 'FIELDS.failures'),
                    sortField: 'failures',
                    formatter: (value) => {
                        return this.padLeft(value)
                    },
                },
                {
                    name: 'status',
                    title: this.getI18n(i18nKey, 'FIELDS.status'),
                    sortField: 'status',
                    formatter: this.formatImportStatuses,
                },
                {
                    name: 'failurecsv',
                    title: this.getI18n(i18nKey, 'FIELDS.failure_file'),
                },
            ],
        }
    },
}
</script>
