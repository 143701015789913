<script>
import ApiModeVuetable from '@/src/components/ApiModeVuetable.vue';
import ImportJobService from '@src/services/ImportJobService'
import Layout from '@layouts/main.vue'
import Breadcrumb from '@/src/components/Breadcrumb.vue'
import PageHeader from '@/src/components/PageHeader.vue'
import Pagination from '@src/mixins/pagination.vue'
import swalFeedback from '@src/mixins/swalFeedback'
import csvFields from '@src/mixins/csvFields'
import vuetableFormatters from '@src/mixins/vuetableFormatters'
import importJobFields from '@src/fields/importJobFields'
import filterVuetable from '@src/mixins/filterVuetable'
// import ImportButton from '@/src/components/ImportButton.vue'
import FilterLabelButton from '@/src/components/FilterLabelButton.vue'
import importJobFilterModal from '@/src/router/views/importJob/importJobFilterModal.vue';
// import SelectCsvTypeModal from '@/src/components/SelectCsvTypeModal.vue'

const i18nCommon = 'COMMON'
const i18nKey = 'IMPORT_JOBS'

export default {
    metaInfo() {
        return {
            title: this.i18nImportJob,
        }
    },
    components: {
        ApiModeVuetable,
        Layout,
        Breadcrumb,
        PageHeader,
        // ImportButton,
        FilterLabelButton,
        importJobFilterModal,
        // SelectCsvTypeModal,
    },
    mixins: [
        Pagination,
        swalFeedback,
        csvFields,
        vuetableFormatters,
        filterVuetable,
        importJobFields,
    ],
    data() {
        return {
            i18nCommon,
            i18nKey,
            modalIsEdit: false,
            additionalParameters: {
                with: ['file'],
            },
            perPage: 10,
            css: {},
            sortOrder: [
                {
                    field: 'created_at',
                    direction: 'desc',
                },
            ],
            filters: {
                createdAt: null,
                type: [],
                status: null,
            },
        }
    },

    computed: {
        items() {
            return [
                {
                    text: 'Home',
                    href: '/',
                },
                {
                    text: this.getI18nModified({
                        prefix: i18nCommon,
                        suffix: 'report',
                        modifier: 2,
                    }),
                    active: true,
                },
                {
                    text: this.getI18nModified({
                        prefix: i18nKey,
                        suffix: 'TITLES.import_job',
                        modifier: 2,
                    }),
                    active: true,
                },
            ]
        },
        i18nImportJob() {
            return this.getI18nModified({
                prefix: i18nKey,
                suffix: 'TITLES.import_job',
                modifier: 2,
            })
        },
        i18nRequestBackground() {
            return this.getI18n('RESPONSES', 'request_background')
        }
    },
    mounted() {
        if (this.$route.params.newJobCreated) {
            this.$bvToast.toast(this.i18nRequestBackground,
            {
                title: this.i18nImportJob,
                variant: 'success',
                autoHideDelay: 8000,
            })
        }
    },
    methods: {
        createAdditionalParameters() {
            return this.formatParameters({
                with: ['file'],
                type: this.filters.type.map((type) => type.value),
                status: this.filters.status ? String(this.filters.status.value) : null,
                created_at: this.formatFilterDate(this.filters.createdAt),
            })
        },

        fetch(url, params) {
            return ImportJobService.fetchVuetable(url, params)
        },

        refreshTable() {
            this.$refs.vuetable.refresh()
        },

        filter(appliedFilters) {
            this.filters = appliedFilters;
            this.additionalParameters = this.createAdditionalParameters()
            this.refreshTable()
        },

        showFilterModal() {
            this.$nextTick(() => this.$refs.importJobFilterModal.showModal(this.filters))
        },

        showImportModal() {
            this.$nextTick(() => this.$refs.selectCsvTypeModal.showModal())
        },
    },
}
</script>

<template>
    <Layout>
        <div class="col-12">
            <Breadcrumb :items="items" />
            <div class="row mt-3">
                <div class="col-sm-6 mb-3 form-inline"></div>
                <div class="col-sm-6 mb-3">
                    <div class="text-sm-right">
                        <filter-label-button @onClick="showFilterModal" />
                        <!-- <import-button
                            v-if="$can('CREATE', 'IMPORTJOB')"
                            @onClick="showImportModal"
                        /> -->
                    </div>
                </div>
            </div>
            <div class="card card-pdv">
                <PageHeader :title="i18nImportJob" />
                <div class="card-body">
                    <api-mode-vuetable
                        ref="vuetable"
                        :fetch-data="fetch"
                        :fields="importJobFields"
                        :per-page="perPage"
                        :additional-parameters="additionalParameters"
                        :sort-order="sortOrder">
                        <template slot="failurecsv" slot-scope="props">
                            <div v-if="props.rowData.file && props.rowData.file.path">
                                <a :href="props.rowData.file.url" target="_blank">
                                    <i class="fe-download" />
                                </a>
                            </div>
                        </template>
                    </api-mode-vuetable>
                </div>
            </div>
        </div>
        <!-- <select-csv-type-modal
            ref="selectCsvTypeModal"
            @refresh="refreshTable"
        /> -->
        <import-job-filter-modal
            ref="importJobFilterModal"
            :handle-submit="filter"
        />
    </Layout>
</template>
